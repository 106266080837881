const fuelGroupsLimitActionTypes = {
    FUEL_LIMIT_FETCH_GROUPS_REQUEST: 'FUEL_LIMIT_FETCH_GROUPS_REQUEST',
    FUEL_LIMIT_FETCH_GROUPS_FULFILLED: 'FUEL_LIMIT_FETCH_GROUPS_FULFILLED',
    FUEL_LIMIT_FETCH_GROUPS_FAIL: 'FUEL_LIMIT_FETCH_GROUPS_FAIL',

    FUEL_LIMIT_FETCH_GROUP_REQUEST: 'FUEL_LIMIT_FETCH_GROUP_REQUEST',
    FUEL_LIMIT_FETCH_GROUP_FULFILLED: 'FUEL_LIMIT_FETCH_GROUP_FULFILLED',
    FUEL_LIMIT_FETCH_GROUP_FAIL: 'FUEL_LIMIT_FETCH_GROUP_FAIL',

    FUEL_LIMIT_CREATE_GROUP_REQUEST: 'FUEL_LIMIT_CREATE_GROUP_REQUEST',
    FUEL_LIMIT_CREATE_GROUP_FULFILLED: 'FUEL_LIMIT_CREATE_GROUP_FULFILLED',
    FUEL_LIMIT_CREATE_GROUP_FAIL: 'FUEL_LIMIT_CREATE_GROUP_FAIL',

    FUEL_LIMIT_UPDATE_GROUP_REQUEST: 'FUEL_LIMIT_UPDATE_GROUP_REQUEST',
    FUEL_LIMIT_UPDATE_GROUP_FULFILLED: 'FUEL_LIMIT_UPDATE_GROUP_FULFILLED',
    FUEL_LIMIT_UPDATE_GROUP_FAIL: 'FUEL_LIMIT_UPDATE_GROUP_FAIL',

    FUEL_LIMIT_CREATE_GROUP_RESET: 'FUEL_LIMIT_CREATE_GROUP_RESET',

    FUEL_LIMIT_DELETE_GROUP_RESET: 'FUEL_LIMIT_DELETE_GROUP_RESET',
    FUEL_LIMIT_DELETE_GROUP_REQUEST: 'FUEL_LIMIT_DELETE_GROUP_REQUEST',
    FUEL_LIMIT_DELETE_GROUP_FULFILLED: 'FUEL_LIMIT_DELETE_GROUP_FULFILLED',
    FUEL_LIMIT_DELETE_GROUP_FAIL: 'FUEL_LIMIT_DELETE_GROUP_FAIL',

    FUEL_LIMIT_FETCH_DRIVERS_REQUEST: 'FUEL_LIMIT_FETCH_DRIVERS_REQUEST',
    FUEL_LIMIT_FETCH_DRIVERS_FULFILLED: 'FUEL_LIMIT_FETCH_DRIVERS_FULFILLED',
    FUEL_LIMIT_FETCH_DRIVERS_FAIL: 'FUEL_LIMIT_FETCH_DRIVERS_FAIL',

    FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_REQUEST: 'FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_REQUEST',
    FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FULFILLED: 'FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FULFILLED',
    FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FAIL: 'FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FAIL',

    FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_REQUEST: 'FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_REQUEST',
    FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FULFILLED: 'FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FULFILLED',
    FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FAIL: 'FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FAIL',

    FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_REQUEST: 'FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_REQUEST',
    FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FULFILLED: 'FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FULFILLED',
    FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FAIL: 'FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FAIL',

    FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_REQUEST: 'FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_REQUEST',
    FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FULFILLED: 'FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FULFILLED',
    FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FAIL: 'FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FAIL',

    FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_REQUEST: 'FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_REQUEST',
    FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FULFILLED: 'FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FULFILLED',
    FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FAIL: 'FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FAIL',
}

export default fuelGroupsLimitActionTypes
