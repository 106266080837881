import {all, call, put} from 'redux-saga/effects'
import {takeLatest} from '@redux-saga/core/effects'
import fuelLimitActionTypes from "./fuelLimitActionTypes";
import {network} from "../network";
import {toast} from "utils/toast";

const alertError = (text, title = "Ошибка") => {
    toast.add({
        title: title,
        text: text,
        color: "danger"
    })
}

function* fetchLimitsGroups(action) {
    const {
        limit,
        offset,
        park_id,
    } = action.payload

    let params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);

    if (park_id) {
        params.append("park_id", park_id);
    }

    try {
        const response = yield call(network.appAxios, {
            url: `erp/2/fuel/limits/groups`,
            method: 'GET',
            params,
        })
        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_FULFILLED,
            payload: {
                summary: response.data,
            },
        })
    } catch (err) {
        if (err.response && err.response.status === 403) {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить список групп...")
    }
}

function* fetchLimitGroup(action) {
    const {id} = action.payload

    try {
        const response = yield call(network.appAxios, {
            url: `/1/fuel/limits/groups/${id}`,
            method: 'GET',
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_FULFILLED,
            payload: {
                depositHistory: response.data
            },
        });
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить данные о группе...")
    }
}

function* createLimitGroup(action) {
    const {name, month_limit, once_limit, day_limit, week_limit} = action.payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/groups`,
            method: 'POST',
            params: {
                name,
                month_limit,
                once_limit,
                day_limit,
                week_limit
            },
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_FULFILLED,
            payload: {
                createdLimitGroup: response.data
            },
        });

        toast.add({
            title: name,
            text: 'Лимит успешно создан',
            color: 'success',
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_FAIL,
            payload: err.response.data,
        })

        alertError("При создании группы возникла ошибка...")
    }
}

function* updateLimitGroup(action) {
    const {id, name, month_limit, once_limit, day_limit, week_limit} = action.payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/groups/${id}`,
            method: 'PUT',
            data: {
                name,
                month_limit,
                once_limit,
                day_limit,
                week_limit
            },
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_FULFILLED,
            payload: {
                updateLimitGroup: response.data
            },
        });

        toast.add({
            title: name,
            text: 'Настройки лимита успешно сохранены',
            color: 'success',
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_FAIL,
            payload: err.response.data,
        })

        alertError("При обновлении группы возникла ошибка...")
    }
}

function* deleteLimitGroup(action) {
    const {id} = action.payload

    try {
        yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/groups/${id}`,
            method: 'DELETE',
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_FULFILLED,
            payload: true
        })

        yield call(fetchLimitsGroups, {
            payload: {
                limit: 20,
                offset: 0,
            }
        })

        toast.add({
            title: 'Успешно',
            text: 'Лимит успешно удален!',
            color: 'success',
        })

    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_FAIL,
                payload: err.response.data,
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_FAIL,
            payload: err.response.data,
        })

        alertError("При удалении группы возникла ошибка...")
    }
}

function* fetchDriversList(action) {
    const {payload} = action

    const {
        limit,
        offset,
        limit_group_id,
        park_aggregator_id,
    } = payload

    let params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);

    if (limit_group_id) {
        params.append("limit_group_id", limit_group_id);
    }

    if (park_aggregator_id) {
        params.append("park_aggregator_id", park_aggregator_id);
    }

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'GET',
            params,
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FULFILLED,
            payload: {
                driverList: response.data,
            },
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить список водителей")
    }
}

function* fetchOutOfGroupDriversList(action) {
    const {payload} = action

    const {
        limit,
        offset,
        park_aggregator_id,
        limit_group_id
    } = payload

    let params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);

    if (limit_group_id) {
        params.append("limit_group_id", limit_group_id);
    } else {
        params.set("limit_group_id", 'none');
    }

    if (park_aggregator_id) {
        params.append("park_aggregator_id", park_aggregator_id);
    }

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'GET',
            params,
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FULFILLED,
            payload: response.data
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить список водителей")
    }
}

function* fetchExcludeDriversList(action) {
    const {payload} = action

    const {
        limit,
        offset,
        exclude_limit_group_id,
        limit_group_id,
        park_aggregator_id,
    } = payload

    let params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);

    if (park_aggregator_id) {
        params.append("park_aggregator_id", park_aggregator_id);
    }

    if (exclude_limit_group_id) {
        params.append("exclude_limit_group_id", exclude_limit_group_id);
    }

    if (limit_group_id) {
        params.append("limit_group_id", limit_group_id);
    } else {
        params.set("limit_group_id", 'none');
    }

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'GET',
            params,
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FULFILLED,
            payload: {
                excludeDriverList: response.data,
            },
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить список водителей")
    }
}

function* fetchWithoutGroupsDriversList(action) {
    const {payload} = action

    const {
        limit,
        offset,
        limit_group_id,
        park_aggregator_id
    } = payload

    let params = new URLSearchParams();
    params.append("limit", limit);
    params.append("offset", offset);

    if (limit_group_id) {
        params.append("limit_group_id", limit_group_id);
    } else {
        params.set("limit_group_id", 'none');
    }

    if (park_aggregator_id) {
        params.append("park_aggregator_id", park_aggregator_id);
    }

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'GET',
            params,
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FULFILLED,
            payload: {
                withoutDriverList: response.data,
            },
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось получить список водителей")
    }
}

function* addDriversToGroup(action) {
    const {payload} = action

    const {
        fuel_limit_group_id,
        billing_balance_id,
    } = payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'POST',
            data: {
                fuel_limit_group_id,
                billing_balance_id
            }
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FULFILLED,
            payload: {
                summary: response.data,
            },
        })

        yield call(fetchExcludeDriversList, {
            payload: payload.excludeRequestParams
        })

        yield call(fetchDriversList, {
            payload: payload.groupDriversParams
        })

        toast.add({
            title: "Успешно",
            text: 'Выбранные водители перемешены в текущую группу лимита',
            color: 'success',
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось добавить водителей в группу")
    }
}

function* removeDriversFromGroup(action) {
    const {payload} = action

    const {
        fuel_limit_group_id,
        billing_balance_id,
    } = payload

    try {
        const response = yield call(network.appAxios, {
            url: `/erp/2/fuel/limits/drivers`,
            method: 'DELETE',
            data: {
                fuel_limit_group_id,
                billing_balance_id,
            }
        })

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FULFILLED,
            payload: {
                removeDriversFromGroup: response.data,
            },
        })

        yield call(fetchDriversList, {
            payload: payload.groupDriversParams
        })

        yield call(fetchExcludeDriversList, {
            payload: payload.excludeRequestParams
        })

        toast.add({
            title: "Успешно",
            text: 'Выбранные водители успешно удалены',
            color: 'success',
        })
    } catch (err) {
        if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
            yield put({
                type: fuelLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FAIL,
                payload: {accessDenied: true},
            })
            return
        }

        yield put({
            type: fuelLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FAIL,
            payload: err.response.data,
        })

        alertError("Не удалось удалить водителей и группы")
    }
}

export default function* fuelGroupsLimitSagas() {
    yield all([
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_REQUEST, fetchLimitsGroups),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_REQUEST, fetchLimitGroup),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_REQUEST, createLimitGroup),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_REQUEST, updateLimitGroup),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_REQUEST, deleteLimitGroup),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_REQUEST, fetchDriversList),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_REQUEST, fetchOutOfGroupDriversList),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_REQUEST, fetchExcludeDriversList),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_REQUEST, fetchWithoutGroupsDriversList),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_REQUEST, addDriversToGroup),
        yield takeLatest(fuelLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_REQUEST, removeDriversFromGroup),
    ])
}
