import React, { createContext, useState, useEffect } from 'react';

const TimerContext = createContext();

const TimerProvider = ({ children }) => {
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);
    const [timerActive, setTimerActive] = useState(false);
    const [isSendAgain, setIsSendAgain] = useState(false);

    useEffect(() => {
        let timeoutId;

        if ((minutes > 0 || seconds > 0) && timerActive) {
            if (seconds === 0) {
                if (minutes > 0) {
                    timeoutId = setTimeout(() => {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59);
                    }, 1000);
                } else {
                    setTimerActive(false);
                    setIsSendAgain(false);
                }
            } else {
                timeoutId = setTimeout(() => {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }, 1000);
            }
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [minutes, seconds, timerActive]);


    useEffect(() => {
        if (timerActive) {
            localStorage.setItem('timerState', JSON.stringify({ minutes, seconds, timerActive }));
        }
    }, [minutes, seconds, timerActive]);

    useEffect(() => {
        const storedTimerState = JSON.parse(localStorage.getItem('timerState'));
        if (storedTimerState) {
            setMinutes(storedTimerState.minutes);
            setSeconds(storedTimerState.seconds);
            setTimerActive(storedTimerState.timerActive);

            if (storedTimerState.timerActive) {
                setIsSendAgain(true);
            }
        }
    }, []);

    return (
        <TimerContext.Provider
            value={{ minutes, setMinutes, seconds, setSeconds, timerActive, setTimerActive, isSendAgain, setIsSendAgain }}
        >
            {children}
        </TimerContext.Provider>
    );
};

export { TimerContext, TimerProvider };