const userActionTypes = {
  USER_RESTORE: 'USER_RESTORE',
  USER_LOGOUT: 'USER_LOGOUT',

  USER_REGISTER_PARK_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_PARK_FULFILLED: 'USER_REGISTER_FULFILLED',
  USER_REGISTER_PARK_FAIL: 'USER_REGISTER_FAIL',

  USER_AUTHORIZATION_REQUEST: 'USER_AUTHORIZATION_REQUEST',
  USER_AUTHORIZATION_FULFILLED: 'USER_AUTHORIZATION_FULFILLED',
  USER_AUTHORIZATION_FAIL: 'USER_AUTHORIZATION_FAIL',

  USER_AUTHENTICATION_REQUEST: 'USER_AUTHENTICATION_REQUEST',
  USER_AUTHENTICATION_FULFILLED: 'USER_AUTHENTICATION_FULFILLED',
  USER_AUTHENTICATION_FAIL: 'USER_AUTHENTICATION_FAIL',

  USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
  USER_PROFILE_FULFILLED: 'USER_PROFILE_FULFILLED',
  USER_PROFILE_FAIL: 'USER_PROFILE_FAIL',

  USER_SET_PASSWORD_REQUEST: 'USER_SET_PASSWORD_REQUEST',
  USER_SET_PASSWORD_FULFILLED: 'USER_SET_PASSWORD_FULFILLED',
  USER_SET_PASSWORD_FAIL: 'USER_SET_PASSWORD_FAIL',

  USER_RESET_PASSWORD_REQUEST: 'USER_RESET_PASSWORD_REQUEST',
  USER_RESET_PASSWORD_FULFILLED: 'USER_RESET_PASSWORD_FULFILLED',
  USER_RESET_PASSWORD_FAIL: 'USER_RESET_PASSWORD_FAIL',

  USER_CONFIRM_EMAIL_REQUEST: 'USER_CONFIRM_EMAIL_REQUEST',
  USER_CONFIRM_EMAIL_FULFILLED: 'USER_CONFIRM_EMAIL_FULFILLED',
  USER_CONFIRM_EMAIL_FAIL: 'USER_CONFIRM_EMAIL_FAIL',

  USER_RESEND_EMAIL_CONFIRMATION_REQUEST: 'USER_RESEND_EMAIL_CONFIRMATION_REQUEST',
  USER_RESEND_EMAIL_CONFIRMATION_FULFILLED: 'USER_RESEND_EMAIL_CONFIRMATION_FULFILLED',
  USER_RESEND_EMAIL_CONFIRMATION_FAIL: 'USER_RESEND_EMAIL_CONFIRMATION_FAIL',

  USER_RESET_PASSWORD_CONFIRM_REQUEST: 'USER_RESET_PASSWORD_CONFIRM_REQUEST',
  USER_RESET_PASSWORD_CONFIRM_FULFILLED: 'USER_RESET_PASSWORD_CONFIRM_FULFILLED',
  USER_RESET_PASSWORD_CONFIRM_FAIL: 'USER_RESET_PASSWORD_CONFIRM_FAIL',

  USER_SEND_PHONE_OTP_REQUEST: 'USER_SEND_PHONE_OTP_REQUEST',
  USER_SEND_PHONE_OTP_FULFILLED: 'USER_SEND_PHONE_OTP_FULFILLED',
  USER_SEND_PHONE_OTP_FAIL: 'USER_SEND_PHONE_OTP_FAIL',

  USER_SEND_PHONE_CONFIRM_OTP_REQUEST: 'USER_SEND_PHONE_CONFIRM_OTP_REQUEST',
  USER_SEND_PHONE_CONFIRM_OTP_FULFILLED: 'USER_SEND_PHONE_CONFIRM_OTP_FULFILLED',
  USER_SEND_PHONE_CONFIRM_OTP_FAIL: 'USER_SEND_PHONE_CONFIRM_OTP_FAIL',

  USER_UPDATE_REQUISITES_REQUEST: 'USER_UPDATE_REQUISITES_REQUEST',
  USER_UPDATE_REQUISITES_FULFILLED: 'USER_UPDATE_REQUISITES_FULFILLED',
  USER_UPDATE_REQUISITES_FAIL: 'USER_UPDATE_REQUISITES_FAIL',

  USER_UPDATE_FULL_NAME_REQUEST: 'USER_UPDATE_FULL_NAME_REQUEST',
  USER_UPDATE_FULL_NAME_FULFILLED: 'USER_UPDATE_FULL_NAME_FULFILLED',
  USER_UPDATE_FULL_NAME_FAIL: 'USER_UPDATE_FULL_NAME_FAIL',
}

export default userActionTypes
