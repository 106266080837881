import React from 'react';
import styled from 'styled-components';
import {
  EuiCard,
  EuiIcon
} from '@elastic/eui';

const Wrapper = styled(EuiCard)`
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  box-shadow: ${({ theme }) => theme.boxShadows.bottomLight};
  padding: 16px 20px;
  text-align: center;
  .euiTitle {
    font-size: 16px;
    line-height: 24px;
  }
  .euiCard__children {
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
  }
  .euiCard__top .euiCard__icon {
    margin-top: 0;
  }
  .euiCard__top, .euiTitle {
    display: flex;
    justify-content: ${({$align}) => $align || 'normal'};
  }
  .euiCard, .euiCard__footer {
    width: auto !important;
  }
`;

const Icon = styled(EuiIcon)`
  height: 40px;
`;

export const SmallCard = (props) => {
  const { className, title, icon, body, footer, align } = props
  return (
    <Wrapper
      $align={align}
      className={className}
      icon={typeof icon === 'string' ? <Icon size='original' type={icon} /> : icon}
      title={title}
      paddingSize='none'
      display
      children={body}
      footer={footer}
    />
  )
}