import { call, all, put } from 'redux-saga/effects'
import { takeLatest } from '@redux-saga/core/effects'
import fuelActionTypes from "./fuelActionTypes";
import { network } from "../network";
import driversActionTypes from "../drivers/driversActionTypes";
import {toast} from "../../utils/toast";
import parkActionTypes from "../park/parkActionTypes";

function* fetchSummary(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/fuel/summary`,
      method: 'GET',
    })

    yield put({
      type: fuelActionTypes.FUEL_SUMMARY_FULFILLED,
      payload: {
        summary: response.data,
      },
    })
  } catch (err) {

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_SUMMARY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelSummaryNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_SUMMARY_FAIL,
        payload: err.response.data,
      })
      return
    }

     if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_SUMMARY_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* fetchDepositHistory(action) {
  const { payload } = action
  const { limit, offset, deposit_types_filter } = payload;

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/fuel/deposit-history`,
      method: 'POST',
      data: {
        limit,
        offset,
        deposit_types_filter
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FULFILLED,
      payload: {
        depositHistory: response.data
      },
    });
    return;
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FAIL,
        payload: {accessDenied: true},
      })
      return
    }
    
    throw err
  }
}

function* fetchFuelHistory(action) {
  const { payload } = action
  const { limit, offset, status, product_code, date_from, date_to, customer_phone, customer_full_name /*deposit_types_filter*/ } = payload

  var params = new URLSearchParams();
  params.append("limit", limit);
  params.append("offset", offset);

  if (date_from) {
    params.append("date_from", date_from);
  }

  if (date_to) {
    params.append("date_to", date_to);
  }

  if (customer_phone) {
    params.append('customer_phone', customer_phone);
  }

  if (customer_full_name) {
    params.append('customer_full_name', customer_full_name);
  }

  if (status && status.length) {
    status?.forEach(status => params.append('status', status));
  }

  if (product_code && product_code.length) {
    product_code?.forEach(product_code => params.append('product_code', product_code));
  }

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/2/fuel/history`,
      method: 'GET',
      params
    })

    yield put({
      type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FULFILLED,
      payload: {
        depositHistory: response.data
      },
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось получить данные',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    };

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FAIL,
        payload: {accessDenied: true},
      })
      alertError();
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelFUELHistoryNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* fetchFuelDetails() {
  // const { payload } = action

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/fuel/details`,
      method: 'GET',
    })

    yield put({
      type: fuelActionTypes.FUEL_DETAILS_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_DETAILS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelSettingsNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_DETAILS_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_DETAILS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_DETAILS_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* fetchFuelPark(action) {
  const { payload } = action

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/2/fuel/park`,
      method: 'GET',
    })

    yield put({
      type: fuelActionTypes.FUEL_PARK_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_PARK_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelDepositHistoryNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_PARK_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_PARK_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_PARK_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* createFuelLimits(action) {
  const limits = action.payload

  try {
    const {data} = yield call(network.appAxios, {
      url: `/erp/1/fuel/details`,
      method: 'POST',
      data: {
        limits
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_LIMITS_CREATE_FULFILLED,
      payload: data
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_CREATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 422 && err.response.data.code === "FuelSettingsValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_CREATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_CREATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateFuelLimits(action) {
  const limits = action.payload

  try {
    const {data} = yield call(network.appAxios, {
      url: `/erp/1/fuel/details`,
      method: 'PATCH',
      data: {
        limits
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_LIMITS_UPDATE_FULFILLED,
      payload: data
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_UPDATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 422 && err.response.data.code === "FuelSettingsValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_CREATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_LIMITS_UPDATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateFuelAvailability(action) {
  const {merchants, channel_name} = action.payload

  try {
    const {data} = yield call(network.appAxios, {
      url: `/erp/2/fuel/availability`,
      method: 'POST',
      data: {
        merchants,
        channel_name
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_AVAILABILITY_UPDATE_FULFILLED,
      payload: data
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_AVAILABILITY_UPDATE_FAIL,
        payload: err.response.data,
      })
      // toast.add({
      //   title: 'Ошибка',
      //   text: contract ? 'Не удалось изменить данные, повторите попытку позже' : 'Не удалось добавить договор, повторите попытку позже',
      //   color: 'danger',
      // })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_AVAILABILITY_UPDATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchFuelHistoryBenzuber(action) {
  const { payload } = action
  const { limit, offset, deposit_types_filter } = payload

  try {
    const response = yield call(network.appAxios, {
      url: `/gateway/3/fuel/history`,
      method: 'GET',
      params: {
        limit,
        offset,
        deposit_types_filter
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FULFILLED,
      payload: {
        depositHistory: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "FuelFUELHistoryNotFound") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* fetchFuelAccountHistory(action) {
  const { payload } = action
  const { limit, offset, /*deposit_types_filter*/ } = payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/2/fuel/account_history`,
      method: 'GET',
      params: {
        limit,
        offset,
      }
    })

    yield put({
      type: fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FULFILLED,
      payload: {
        accountHistory: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }
    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FAIL,
        payload: err.response.data,
      })
      return
    }
    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FAIL,
        payload: {accessDenied: true},
      })
      return
    }
    throw err
  }
}

export default function* fuelSagas() {
  yield all([
    yield takeLatest(fuelActionTypes.FUEL_SUMMARY_REQUEST, fetchSummary),
    yield takeLatest(fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_REQUEST, fetchDepositHistory),
    yield takeLatest(fuelActionTypes.FUEL_DETAILS_REQUEST, fetchFuelDetails),
    yield takeLatest(fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_REQUEST, fetchFuelHistory),
    yield takeLatest(fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_REQUEST, fetchFuelHistoryBenzuber),
    yield takeLatest(fuelActionTypes.FUEL_LIMITS_CREATE_REQUEST, createFuelLimits),
    yield takeLatest(fuelActionTypes.FUEL_LIMITS_UPDATE_REQUEST, updateFuelLimits),
    yield takeLatest(fuelActionTypes.FUEL_PARK_REQUEST, fetchFuelPark),
    yield takeLatest(fuelActionTypes.FUEL_AVAILABILITY_UPDATE_REQUEST, updateFuelAvailability),
    yield takeLatest(fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_REQUEST, fetchFuelAccountHistory),
  ])
}
