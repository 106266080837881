import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import userReducer from "./user/userReducer";
import transactionsReducer from "./transactions/transactionsReducer";
import appReducer from "./app/appReducer";
import employeesReducer from "./employees/employeesReducer";
import parkReducer from "./park/parkReducer";
import fuelReducer from "./fuel/fuelReducer";
import subscriptionReducer from "./subscription/subscriptionReducer";
import referralReducer from "./referral/referralReducer";
import receiptReducer from "./receipt/receiptReducer";
import driversReducer from "./drivers/driversReducer";
import analyticsReducer from "./analytics/analyticsReducer";
import autoregReducer from "./autoreg/autoregReducer";
import fuelGroupsLimitReducer from "./fuelLimit/fuelLimitReducer";

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    transactions: transactionsReducer,
    employees: employeesReducer,
    park: parkReducer,
    fuel: fuelReducer,
    fuelGroupsLimit: fuelGroupsLimitReducer,
    subscription: subscriptionReducer,
    referral: referralReducer,
    receipt: receiptReducer,
    drivers: driversReducer,
    analytics: analyticsReducer,
    autoreg: autoregReducer,
})

export default rootReducer
