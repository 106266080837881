import fuelGroupsLimitActionTypes from "./fuelLimitActionTypes";

const initialState = {
    limitsGroups: {
        loading: false,
        data: null,
        accessDenied: false
    },
    limitGroup: {
        loading: false,
        data: null,
        accessDenied: false
    },
    updateLimitGroup: {
        loading: false,
        data: null,
        accessDenied: false
    },
    deleteLimitGroup: {
        loading: false,
        deleted: false,
        accessDenied: false
    },
    outOfGroupDriverList: {
        loading: false,
        data: null,
        accessDenied: false
    },
    driverList: {
        loading: false,
        data: null,
        accessDenied: false
    },
    excludeDriverList: {
        loading: false,
        data: null,
        accessDenied: false
    },
    withoutDriverList: {
        loading: false,
        data: null,
        accessDenied: false
    },
    addDriversToGroup: {
        loading: false,
        data: null,
        accessDenied: false
    },
    removeDriversFromGroup: {
        loading: false,
        data: null,
        accessDenied: false
    },
}


const reducers = {
    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            limitsGroups: {
                ...state.limitsGroups,
                loading: false,
                data: payload.summary.items,
                accessDenied: false
            }
        }
    },
    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_GROUPS_REQUEST]: (state, payload) => {
        return {
            ...state,
            limitsGroups: {
                ...state.limitsGroups,
                loading: true,
                data: null,
            }
        }
    },
    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_FULFILLED]: (state, payload) => {
        return {
            ...state,
            limitGroup: {
                ...state.limitGroup,
                loading: false,
                data: payload.limitGroup,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_GROUP_REQUEST]: (state, payload) => {
        return {
            ...state,
            limitGroup: {
                ...state.limitGroup,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_FULFILLED]: (state, payload) => {
        return {
            ...state,
            createdLimitGroup: {
                ...state.createdLimitGroup,
                showCloseActionPopUp: true,
                loading: false,
                data: payload.createdLimitGroup,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_REQUEST]: (state, payload) => {
        return {
            ...state,
            createdLimitGroup: {
                ...state.createdLimitGroup,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_CREATE_GROUP_RESET]: (state, payload) => {
        return {
            ...state,
            createdLimitGroup: {
                ...state.createdLimitGroup,
                showCloseActionPopUp: false,
            },
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_FULFILLED]: (state, payload) => {
        console.log(payload);
        return {
            ...state,
            updateLimitGroup: {
                ...state.updateLimitGroup,
                loading: false,
                data: payload.updateLimitGroup,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_UPDATE_GROUP_REQUEST]: (state, payload) => {
        return {
            ...state,
            updateLimitGroup: {
                ...state.updateLimitGroup,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_RESET]: (state, payload) => {
        return {
            ...state,
            deleteLimitGroup: {
                loading: false,
                deleted: false,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_REQUEST]: (state, payload) => {
        return {
            ...state,
            deleteLimitGroup: {
                loading: true,
                deleted: false,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_FULFILLED]: (state, payload) => {
        return {
            ...state,
            deleteLimitGroup: {
                loading: false,
                deleted: payload,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_DELETE_GROUP_FAIL]: (state, payload) => {
        return {
            ...state,
            deleteLimitGroup: {
                loading: false,
                deleted: false,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            driverList: {
                ...state.driverList,
                loading: false,
                data: payload.driverList,
                accessDenied: false,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_DRIVERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            driverList: {
                ...state.driverList,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            outOfGroupDriverList: {
                ...state.outOfGroupDriverList,
                loading: true,
                data: null,
            }
        }
    },
    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_OUT_OF_GROUP_DRIVERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            outOfGroupDriverList: {
                ...state.outOfGroupDriverList,
                loading: false,
                data: payload,
                accessDenied: false,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            excludeDriverList: {
                ...state.excludeDriverList,
                loading: false,
                data: payload.excludeDriverList,
                accessDenied: false
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_EXCLUDE_DRIVERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            excludeDriverList: {
                ...state.excludeDriverList,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            withoutDriverList: {
                ...state.withoutDriverList,
                loading: false,
                data: payload.withoutDriverList,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_FETCH_WITHOUT_GROUPS_DRIVERS_REQUEST]: (state, payload) => {
        return {
            ...state,
            withoutDriverList: {
                ...state.withoutDriverList,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            addDriversToGroup: {
                ...state.addDriversToGroup,
                loading: false,
                data: payload.addDriversToGroup,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_ADD_DRIVERS_TO_GROUPS_REQUEST]: (state, payload) => {
        return {
            ...state,
            addDriversToGroup: {
                ...state.addDriversToGroup,
                loading: true,
                data: null,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_FULFILLED]: (state, payload) => {
        return {
            ...state,
            removeDriversFromGroup: {
                ...state.removeDriversFromGroup,
                loading: false,
                data: payload.removeDriversFromGroup,
            }
        }
    },

    [fuelGroupsLimitActionTypes.FUEL_LIMIT_REMOVE_DRIVERS_FROM_GROUPS_REQUEST]: (state, payload) => {
        return {
            ...state,
            removeDriversFromGroup: {
                ...state.removeDriversFromGroup,
                loading: false,
                data: payload.removeDriversFromGroup,
            }
        }
    },
}

export default function fuelGroupsLimitReducer(state = initialState, action) {
    if (reducers[action.type]) {
        return reducers[action.type](state, action.payload)
    }
    return state
};
