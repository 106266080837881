import fuelActionTypes from "./fuelActionTypes";

const initialState = {
  summary: {
    loading: false,
    data: null,
    accessDenied: false
  },
  depositHistory: {
    loading: false,
    data: null,
    accessDenied: false
  },
  fuelHistory: {
    loading: false,
    data: null,
    accessDenied: false
  },
  fuelHistoryBenzuber: {
    loading: false,
    data: null,
    accessDenied: false
  },
  fuelPark: {
    loading: false,
    data: null,
    accessDenied: false
  },
  details: {
    loading: false,
    data: null,
    accessDenied: false
  },
  accountHistory: {
    loading: false,
    data: null,
    accessDenied: false
  },
  fuelEnabled: {
    loading: false,
    data: null,
    accessDenied: false
  },
}


const reducers = {
  [fuelActionTypes.FUEL_SUMMARY_REQUEST]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_SUMMARY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: false,
        data: payload.summary,
      }
    }
  },
  [fuelActionTypes.FUEL_SUMMARY_FAIL]: (state, payload) => {
    return {
      ...state,
      summary: {
        ...state.summary,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_REQUEST]: (state, payload) => {
    return {
      ...state,
      depositHistory: {
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      depositHistory: {
        ...state.depositHistory,
        loading: false,
        data: payload.depositHistory,
        accessDenied: false
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_DEPOSIT_HISTORY_FAIL]: (state, payload) => {
    return {
      ...state,
      depositHistory: {
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [fuelActionTypes.FUEL_DETAILS_REQUEST]: (state) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_DETAILS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: false,
        data: payload,
      }
    }
  },
  [fuelActionTypes.FUEL_DETAILS_FAIL]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [fuelActionTypes.FUEL_LIMITS_CREATE_FULFILLED]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          account: {...state?.details?.account},
          ...payload
        }
      }
    }
  },
  [fuelActionTypes.FUEL_LIMITS_UPDATE_FULFILLED]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          account: {...state?.details?.data?.account},
          ...payload
        }
      }
    }
  },
  [fuelActionTypes.FUEL_SAVE_LIMITS]: (state, limits) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details?.data,
          limits
        }
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_REQUEST]: (state, payload) => {
    return {
      ...state,
      fuelHistory: {
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      fuelHistory: {
        ...state.depositHistory,
        loading: false,
        data: payload.depositHistory,
        accessDenied: false
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_FAIL]: (state, payload) => {
    return {
      ...state,
      fuelHistory: {
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_REQUEST]: (state, payload) => {
    return {
      ...state,
      fuelHistoryBenzuber: {
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      fuelHistoryBenzuber: {
        ...state.fuelHistoryBenzuber,
        loading: false,
        data: payload.depositHistory,
        accessDenied: false
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_FUEL_HISTORY_BENZUBER_FAIL]: (state, payload) => {
    return {
      ...state,
      fuelHistoryBenzuber: {
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [fuelActionTypes.FUEL_PARK_REQUEST]: (state) => {
    return {
      ...state,
      fuelPark: {
        ...state.fuelPark,
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_PARK_FULFILLED]: (state, payload) => {
    return {
      ...state,
      fuelPark: {
        ...state.fuelPark,
        loading: false,
        data: payload,
      }
    }
  },
  [fuelActionTypes.FUEL_PARK_FAIL]: (state, payload) => {
    return {
      ...state,
      fuelPark: {
        ...state.fuelPark,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_REQUEST]: (state) => {
    return {
      ...state,
      accountHistory: {
        ...state.accountHistory,
        loading: true,
        data: null,
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      accountHistory: {
        ...state.accountHistory,
        loading: false,
        data: payload,
      }
    }
  },
  [fuelActionTypes.FUEL_FETCH_ACCOUNT_HISTORY_FAIL]: (state, payload) => {
    return {
      ...state,
      accountHistory: {
        ...state.accountHistory,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },
}

export default function fuelReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
