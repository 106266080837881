import userActionTypes from './userActionTypes.js'

const initialState = {
  profile: false,
  isAuthenticated: false,
  loading: false,
  attemptId: null,
  error: null,

  password: {
    loading: false,
  }
}

const reducers = {
  [userActionTypes.USER_RESTORE]: (state, payload) => {
    return {
      ...state,
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
    }
  },

  [userActionTypes.USER_AUTHORIZATION_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),

  [userActionTypes.USER_AUTHORIZATION_FULFILLED]: (state, payload) => ({
    ...state,
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
  }),

  [userActionTypes.USER_AUTHORIZATION_FAIL]: (state) => ({
    ...state,
    loading: false,
  }),

  [userActionTypes.USER_AUTHENTICATION_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),

  [userActionTypes.USER_AUTHENTICATION_FULFILLED]: (state, payload) => {
    return {
    ...state,
    profile: payload.profile,
    isAuthenticated: true,
    loading: false,
  }},

  [userActionTypes.USER_AUTHENTICATION_FAIL]: (state) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
  }),

  [userActionTypes.USER_LOGOUT]: (state) => ({
    ...state,
    ...initialState,
  }),

  [userActionTypes.USER_SET_PASSWORD_REQUEST]: (state, payload) => ({
    ...state,
    password: {
      errors: null,
      loading: true,
    }
  }),

  [userActionTypes.USER_SET_PASSWORD_FULFILLED]: (state, payload) => ({
    ...state,
    password: {
      errors: null,
      loading: false,
    }
  }),

  [userActionTypes.USER_SET_PASSWORD_FAIL]: (state, payload) => ({
    ...state,
    password: {
      errors: payload,
      loading: false,
    }
  }),

  [userActionTypes.USER_SEND_PHONE_OTP_REQUEST]: (state) => ({
    ...state,
    loading: true
  }),

  [userActionTypes.USER_SEND_PHONE_OTP_FULFILLED]: (state, payload) => {
    return {
      ...state,
      loading: false,
      attemptId: payload.data.attemptId,
      error: null,
    }
  },

  [userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),

  [userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FULFILLED]: (state) => ({
    ...state,
    loading: false,
    error: null,
    profile: {
      ...state.profile,
      phone_confirmed: true,
    }
  }),

  [userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL]: (state, payload) => ({
    ...state,
    loading: false,
    error: payload.error,
  }),
}

export default function userReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
